@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "KARBON";
  src: url("../public/fonts/Karbon-RegularTest.otf");
}

@font-face {
  font-family: "KARBONBOLD";
  src: url("../public/fonts/Karbon-BoldTest.otf");
}

@font-face {
  font-family: "PITCHSANS";
  src: url("../public/fonts/PitchSans-SemiboldTest.otf");
}

@font-face {
  font-family: "BIORHYME";
  src: url("../public/fonts/BioRhyme-Regular.ttf");
}

.App {
  overflow-x: hidden;
}

#vidContainer {
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh; 
  /* overflow: hidden; */
}

#heroBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  object-fit: cover;
}

#logo, #subText {
  transition: all .7s ease-in-out;
}

.logoOut {
  transform: scale(.2);
  opacity: 0;
  transition: all .7s ease-in-out;
}


.logoFlashBig {
  transform: scale(1.1);
  filter: drop-shadow(1px 1px 90px rgba(253, 237, 171, 0.6)); 
  transition: all .7s ease-in-out;
}

.logoFlashLittle {
  filter: drop-shadow(1px 1px 120px rgba(253, 237, 171, .3)); 
  transition: all .7s ease-in-out;
}

.logoFlashOut {
  filter: drop-shadow(1px 1px 180px rgba(253, 237, 171, .1)); 
  transition: all .7s ease-in-out;
}

#arrow {
  -webkit-transition: all .25s linear;
  -moz-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
  position: absolute;
}

.lgArrow {
  top: 82%;
  left: 31%;
}

.mdArrow{
  top: 85%;
  left: 22%;
}

.smArrow{
  top: 77%;
  left: 0;
}

.chevron {
  
  animation-duration: 1.5s; 
  animation-name: bounce; 
  animation-iteration-count: 2;
}

@keyframes bounce { 
  0%, 20%, 50%, 80%, 100% {transform: translateX(0);} 
  40% {transform: translateX(20px);} 
  60% {transform: translateX(10px);} 
}

#hireBtn {
  text-shadow: 1px 1px 5px rgba(0,0,0,0.74);}

.biggerArrow {
  transform: scale(1.2);
}

.fadeOut {
  opacity: .3;
  transition: opacity 2s ease;
}

.easeIt{
  opacity: 1;
  transition: opacity 2s ease;
}

.easeItOut{
  opacity: 0;
  transition: opacity 2s ease;
}

.txtShadow {
  -webkit-text-stroke-width: .5px;
  -webkit-text-stroke-color: rgba(0,0,0,0.3);
  text-shadow: 4px 4px 4px #000000;
}

.heroTxt {
  -webkit-mask-image: linear-gradient(120deg, transparent 50%, #fff 60%);
  -webkit-mask-position: right;
  -webkit-mask-size: 250% 100%;
  animation: hide 1s linear forwards;
}

.heroTxtIn {
  /* color: #fff; */
  -webkit-mask-image: linear-gradient(120deg,  #fff 60%, transparent 50%);
  -webkit-mask-position: right;
  -webkit-mask-size: 250% 100%;
  animation: hide 1s linear forwards;
}

@keyframes hide {
  to {
    -webkit-mask-position: left;
  }
}
/* #mainLogo {
  filter: drop-shadow(2px 33px 27px #fff);
} */